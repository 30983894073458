<template>
  <v-container id="contents" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="contentsFiltered"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :search="search"
      :no-data-text="str['contents_empty']"
      :no-results-text="str['contents_empty']"
      class="elevation-1"
      @click:row="openEditContent"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-dialog v-model="dialogContent" persistent max-width="750px">
            <template #activator="{ on, attrs }">
              <v-btn v-if="editPermission" color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="openNewContent()">
                {{ str['add'] }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="!contentToEdit" class="headline">
                  {{ str['new_content'] }}
                </span>
                <span v-if="contentToEdit" class="headline">
                  {{ str['edit_content'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in visibleData"
                      :key="item.id"
                      :cols="item.cols ? item.cols : 12"
                      :style="{
                        'pointer-events': editPermission ? '' : 'none',
                      }"
                      :class="{ 'pb-0': item.type === 'checkbox' }"
                    >
                      <v-text-field
                        v-if="item.type === 'input' && item.inputType !== 'numeric' && !item.multiple"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-text-field
                        v-if="item.type === 'input' && item.inputType === 'numeric' && !item.multiple"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        hide-details
                      />
                      <v-textarea
                        v-if="item.type === 'textarea'"
                        v-model="item.value"
                        :label="item.title + (item.required ? ' *' : '')"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />
                      <v-checkbox
                        v-if="item.type === 'checkbox'"
                        v-model="item.value"
                        :label="item.title"
                        class="form-field-checkbox"
                        hide-details
                        @change="item.onChange ? item.onChange(item) : null"
                      />
                      <div v-if="item.type === 'input' && item.multiple">
                        <div v-for="(url, index) in item.value" :key="index" class="row-flex-align">
                          <v-text-field v-model="item.value[index]" :label="str['url']" />
                          <v-btn v-if="editPermission" class="ml-6" fab dark small color="warning" @click="deleteUrl(index)">
                            <v-icon dark>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="row-flex-align">
                          <v-text-field v-model="newUrl" :label="str['new_url']" hide-details />
                          <v-btn v-if="editPermission" class="ml-6" fab dark small color="success" @click="saveNewUrl">
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div v-if="item.type === 'image'">
                        <div>{{ str['image'] }}{{ item.required ? ' *' : '' }}</div>
                        <div v-if="item.value" class="form-field-image">
                          <input id="contents-input-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewImage" />
                          <v-img contain :src="item.value" alt="Conteúdo" width="300px" height="300px" />
                        </div>
                        <div v-if="!item.value && editPermission" class="form-field-image">
                          <input id="contents-input-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewImage" />
                          <v-btn class="mx-2" fab dark small color="success">
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :no-data-text="str['no_data']"
                        :multiple="item.multiple"
                        hide-details
                        @change="changeSelect(item)"
                      />
                      <div v-if="item.type === 'pdf'">
                        <div v-if="!item.value" class="row-flex-align-center">
                          <v-btn color="blue" class="mr-0">
                            {{ str['add_pdf'] }}
                          </v-btn>
                          <input
                            id="input-pdf-value"
                            type="file"
                            accept="application/pdf,application/vnd.ms-excel"
                            style="opacity: 0; position: absolute; height: 45px; width: 160px"
                            @change="loadPdf"
                          />
                        </div>
                        <div v-if="item.value" class="row-flex-align-center">
                          <v-btn color="error" class="mr-0" @click="item.value = ''">
                            {{ str['remove_pdf'] }}
                          </v-btn>
                        </div>
                        <div v-if="item.value" class="pt-2">
                          <iframe :src="item.value" style="width: 100%; height: 50vh" />
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="default" @click="closeContent">
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn v-if="editPermission" color="success" @click="saveContent">
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar v-if="useTypes && filterContentTypes.length" flat class="toolbar-adjust">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="typeFilter"
                :label="str['type']"
                item-text="label"
                item-value="value"
                :items="filterContentTypes"
                :no-data-text="str['no_data']"
                hide-details
                clearable
                style="max-width: 300px"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template #item.image="{ item }">
        <div class="p-2 text-center" style="display: flex; justify-content: center; padding: 10px 0">
          <v-img contain :src="item.image" :alt="item.name" width="60px" height="60px" />
        </div>
      </template>
      <template #item.description="{ item }">
        <div class="ellipsis-rows-1">
          {{ item.description }}
        </div>
      </template>
      <template #item.url="{ item }">
        <div class="text-center" style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: 0 auto">
          <a
            v-if="
              config &&
              (!config.contents ||
                (config.contents && !config.contents.multiple_urls) ||
                (config.contents && config.contents.multiple_urls && item.url.length === 1))
            "
            target="_blank"
            :href="item.url"
            @click.stop
          >
            {{ config.contents && config.contents.multiple_urls ? item.url[0] : item.url }}
          </a>
          <div v-if="config && config.contents && config.contents.multiple_urls && item.url.length > 1">
            {{ item.url.length }} {{ str['urls'] }}
          </div>
        </div>
      </template>
      <template #item.type="{ item }">
        {{
          contentTypesDict[item.type]
            ? str[contentTypesDict[item.type].label]
              ? str[contentTypesDict[item.type].label]
              : contentTypesDict[item.type].label
            : '-'
        }}
      </template>
      <template #item.sub_type="{ item }">
        {{
          contentType && contentType[item.type] && contentType[item.type].sub_types && contentType[item.type].sub_types[item.sub_type]
            ? str[contentType[item.type].sub_types[item.sub_type].label]
              ? str[contentType[item.type].sub_types[item.sub_type].label]
              : contentType[item.type].sub_types[item.sub_type].label
            : contentSubTypesDict[item.sub_type]
              ? str[contentSubTypesDict[item.sub_type].label]
                ? str[contentSubTypesDict[item.sub_type].label]
                : contentSubTypesDict[item.sub_type].label
              : '-'
        }}
      </template>
      <template #item.actions="{ item }">
        <v-icon v-if="editPermission" small class="mr-0" color="error" @click.stop="deleteContent(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const editPermission = Utils.hasPermission('content_list_edit')
    const headers = []
    const form = []
    const useTypes = config && config.contents && config.contents.use_types ? true : false
    const useSubTypes = config && config.contents && config.contents.use_sub_types ? true : false
    const hideTableDescription = config && config.contents && config.contents.hide_table_description ? true : false
    const hasTranslations = config && config.contents && config.contents.has_translations ? config.contents.has_translations : false
    let contentTypes = []

    headers.push({ text: window.strings['id'], value: 'id', width: 60 })
    if (config && config.contents && config.contents.has_image) {
      headers.push({
        text: window.strings['image'],
        value: 'image',
        sortable: false,
        align: 'center',
        width: 100,
      })
    }
    headers.push({
      text: window.strings['name'],
      value: 'name',
      align: 'left',
      width: 180,
    })
    if (useTypes) {
      headers.push({
        text: window.strings['type'],
        value: 'type',
        align: 'center',
        width: 150,
      })
    }
    if (useSubTypes) {
      headers.push({
        text: window.strings['sub_type'],
        value: 'sub_type',
        align: 'center',
        width: 150,
      })
    }
    if (!hideTableDescription) {
      headers.push({
        text: window.strings['description'],
        value: 'description',
        align: 'left',
        width: 180,
      })
    }
    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 70,
      })
    }

    form.push({
      type: 'input',
      id: 'name',
      title: window.strings['name'],
      value: '',
      visible: true,
      required: true,
    })
    if (hasTranslations) {
      hasTranslations.forEach(function (tr) {
        form.push({
          type: 'input',
          id: 'name_' + tr,
          title: window.strings['name'] + ' (' + tr.toUpperCase() + ')',
          value: '',
          visible: true,
          required: false,
        })
      })
    }

    if (useTypes) {
      contentTypes = Utils.getContentTypes()
      form.push({
        type: 'select',
        id: 'type',
        title: window.strings['type'],
        items: contentTypes,
        value: '',
        visible: true,
        required: true,
      })
    }

    if (useSubTypes) {
      form.push({
        type: 'select',
        id: 'sub_type',
        title: window.strings['sub_type'],
        items: Utils.getContentSubTypes(),
        value: '',
        visible: true,
        required: true,
      })
    }

    form.push({
      type: 'textarea',
      id: 'description',
      title: window.strings['description'],
      value: '',
      visible: true,
      isDescription: true,
    })
    if (hasTranslations) {
      hasTranslations.forEach(function (tr) {
        form.push({
          type: 'textarea',
          id: 'description_' + tr,
          title: window.strings['description'] + ' (' + tr.toUpperCase() + ')',
          value: '',
          visible: true,
          isDescription: true,
        })
      })
    }

    form.push({
      type: 'input',
      id: 'url',
      title: window.strings['url'],
      value: '',
      visible: true,
      required: config && config.contents && config.contents.use_optional_url ? false : true,
      multiple: config && config.contents && config.contents.multiple_urls ? true : false,
    })

    if (config && config.contents && config.contents.has_sale) {
      form.push({
        type: 'checkbox',
        id: 'sale',
        title: window.strings['for_sale'],
        value: '',
        visible: true,
        required: false,
        onChange: (item) => {
          this.data.forEach((f) => {
            if (f.id === 'sale_price' || f.id === 'sale_price_description' || f.id === 'sale_url') {
              f.visible = item.value ? true : false
              if (!f.visible) {
                f.value = ''
              }
            }
          })
        },
      })
      form.push({
        type: 'input',
        inputType: 'numeric',
        id: 'sale_price',
        title: window.strings['price'],
        value: '',
        visible: true,
        required: true,
        cols: 3,
      })
      form.push({
        type: 'input',
        id: 'sale_price_description',
        title: window.strings['sale_price_description'],
        value: '',
        visible: true,
        required: true,
        cols: 9,
      })
      if (config.contents.has_sale_url) {
        form.push({
          type: 'input',
          id: 'sale_url',
          title: window.strings['sale_url'],
          value: '',
          visible: true,
          required: true,
        })
      }
    }

    if (config && config.contents && config.contents.has_pdf) {
      form.push({
        type: 'pdf',
        id: 'pdf',
        title: window.strings['pdf'],
        value: '',
        visible: true,
        required: false,
      })
    }

    if (config && config.contents && config.contents.has_image) {
      form.push({
        type: 'image',
        id: 'image',
        title: window.strings['image'],
        value: '',
        visible: true,
        required: config.contents && config.contents.use_optional_image ? false : true,
      })
    }

    let filterContentTypes = []
    if (useTypes && contentTypes.length) {
      filterContentTypes = [
        {
          label: window.strings['all'],
          value: '',
        },
      ]
      filterContentTypes.push(...contentTypes)
    }

    return {
      str: window.strings,
      user: user,
      config: config,
      editPermission: editPermission,
      hasTranslations: hasTranslations,
      headers: headers,
      data: form,
      useTypes: useTypes,
      useSubTypes: useSubTypes,
      filterContentTypes: filterContentTypes,
      contentTypesDict: this.getContentTypesDict(),
      contentSubTypesDict: this.getContentSubTypesDict(),
      contentType: Utils.getContentType(),
      imageMaxSize: 600,
      contentsMinType: 99,
      supplementType: 100,
      recipesPacksType: 101,
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('contents', 'itemsPerPage') || 10,
      contents: [],
      search: '',
      dialogContent: false,
      contentToEdit: null,
      newUrl: '',
      typeFilter: '',
      activeStatus: 0,
      showImages: true,
    }
  },
  computed: {
    visibleData() {
      return this.data.filter((item) => item.visible)
    },
    contentsFiltered() {
      const self = this
      let contents = this.contents
      if (this.useTypes && this.typeFilter !== null && this.typeFilter !== '') {
        contents = contents.filter(function (item) {
          return item.type === self.typeFilter
        })
      }
      return contents
    },
  },
  watch: {
    dialogContent(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }

      val || this.closeContent()
    },
  },
  beforeMount: function () {
    this.main()
  },
  beforeDestroy: function () {
    Utils.setTableCache('contents', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('contents', 'page', this.currentPage)
  },
  methods: {
    main() {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.getContents(true)
      if (!window.useResetCacheSockets) {
        Utils.removeRequestCache('contents')
      }
    },
    getContentTypesDict() {
      const statusList = Utils.getContentTypes()
      const dict = {}
      if (statusList && statusList.length) {
        statusList.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getContentSubTypesDict() {
      const statusList = Utils.getContentSubTypes()
      const dict = {}
      if (statusList && statusList.length) {
        statusList.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getContents: function (getCachePage) {
      const self = this
      Api.getContents(
        {
          minType: this.contentsMinType,
          photo: false,
          fields: ['date', 'id', 'name', 'description', 'status', 'sub_type', 'type'],
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.contents = response.data
            self.setContentsImages()
            if (getCachePage) {
              self.currentPage = Utils.getTableCache('contents', 'page') || self.currentPage
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    setContentsImages: function (idsToRefresh) {
      if (!this.showImages) return false

      const self = this
      let contentsIds = []

      if (idsToRefresh) {
        idsToRefresh.forEach((idRef) => {
          delete window.cachePhotos[`content-${idRef}`]
        })
        contentsIds = idsToRefresh
      } else {
        contentsIds = this.contents.map((item) => item.id)

        this.contents.forEach((content, index) => {
          const cachedImage = window.cachePhotos[`content-${content.id}`]
          if (cachedImage) {
            content.image = cachedImage
            contentsIds = contentsIds.filter((id) => id !== content.id)
          } else {
            content.image = require('@/assets/loading.gif')
          }
          this.$set(this.contents, index, content)
        })
      }

      const splitIds = Utils.splitArray(contentsIds, 5)
      fetchImages(0)

      function fetchImages(index) {
        if (splitIds[index] && self.$route.path === '/home/contents') {
          Api.getContents(
            {
              ids: splitIds[index],
              photo: true,
              fields: ['id', 'image'],
            },
            function (response) {
              if (response.success) {
                updateImages(response.data, splitIds[index])
                fetchImages(index + 1)
              }
            },
          )
        }
      }

      function updateImages(data, ids) {
        const imagesMap = data.reduce((map, item) => {
          map[item.id] = item.image
          return map
        }, {})

        self.contents.forEach((content, index) => {
          if (ids.includes(content.id)) {
            const newImage = imagesMap[content.id] || null
            window.cachePhotos[`content-${content.id}`] = newImage
            content.image = newImage
            self.$set(self.contents, index, content)
          }
        })
      }
    },
    openNewContent() {
      this.setData()
      this.contentToEdit = null
      this.dialogContent = true
    },
    openEditContent(content) {
      const self = this
      this.$isLoading(true)
      Api.getContents(
        {
          id: content.id,
          photo: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success && response.data && response.data[0]) {
            const src = self.encodeContent(response.data[0])
            self.setData(src)
            self.contentToEdit = src
            self.dialogContent = true
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    encodeContent(data) {
      if (this.config && this.config.contents && this.config.contents.multiple_urls) {
        if (!data.url) {
          data.url = []
        } else {
          try {
            data.url = JSON.parse(data.url)
          } catch (error) {
            data.url = []
          }
        }
      }
      return data
    },
    setData: function (content) {
      this.newUrl = ''
      for (let i = 0; i < this.data.length; i++) {
        if (content) {
          let val = content[this.data[i].id]
          if (this.data[i].id === 'sale_price') {
            val = content[this.data[i].id] ? content[this.data[i].id] / 100 : content[this.data[i].id]
          }
          this.data[i].value = val
        } else {
          this.data[i].value = ''
        }
      }
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].onChange) {
          this.data[i].onChange(this.data[i])
        }
      }
      this.refreshFields(content)
    },
    closeContent() {
      this.contentToEdit = null
      this.dialogContent = false
    },
    saveContent() {
      const self = this
      const data = this.getData()

      if (data) {
        this.$isLoading(true)

        if (data.id) {
          Api.updateContent(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeContent()
              for (let i = 0; i < self.contents.length; i++) {
                if (self.contents[i].id === data.id) {
                  self.$set(self.contents, i, self.encodeContent(response.data))
                  break
                }
              }
              self.setContentsImages([response.data.id])
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          Api.newContent(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeContent()
              self.contents.push(self.encodeContent(response.data))
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        }
      }
    },
    getData: function () {
      const data = {
        description: '',
      }
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].visible && this.data[i].required && !this.data[i].value && this.data[i].type !== 'select') {
          if (this.data[i].id === 'url') {
            this.$alert(window.strings['missing_content_video'], '', 'warning', Utils.getAlertOptions())
          } else {
            this.$alert(window.strings['fields_to_be_filled'], '', 'warning', Utils.getAlertOptions())
          }
          return false
        }
        if (this.data[i].id === 'url') {
          if (this.config && this.config.contents && this.config.contents.multiple_urls) {
            if (!this.data[i].value) {
              this.data[i].value = []
            }
            data[this.data[i].id] = JSON.stringify(this.data[i].value)
          } else {
            data[this.data[i].id] = this.data[i].value
          }
          continue
        }
        if (this.data[i].id === 'description') {
          data.description = this.data[i].value
          continue
        }
        if (this.data[i].isDescription) {
          data.description = data.description ? data.description : {}
          if (typeof data.description === 'object') {
            data.description[this.data[i].id] = this.data[i].value
          }
          continue
        }
        if (this.data[i].id === 'sale_price') {
          data[this.data[i].id] = Math.round(this.data[i].value * 100)
          continue
        }
        if (this.data[i].id === 'sale') {
          data[this.data[i].id] = this.data[i].value ? 1 : 0
          continue
        }
        data[this.data[i].id] = this.data[i].value
      }

      if (typeof data.description === 'object') {
        data.description = JSON.stringify(data.description)
      }

      data.status = 0

      if (this.contentToEdit) {
        data.id = this.contentToEdit.id
      }

      return data
    },
    deleteContent(content) {
      const self = this
      const contentId = content.id

      this.$confirm(
        window.strings['want_delete_content'] + (content ? ' "' + content.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteContent(
            {
              id: contentId,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                for (let i = 0; i < self.contents.length; i++) {
                  if (self.contents[i].id === contentId) {
                    self.contents.splice(i, 1)
                    break
                  }
                }
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    addNewImage(event) {
      const self = this
      const file = event.srcElement.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = function (e) {
        const image = new Image()
        image.src = reader.result

        image.onload = function () {
          if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
            self.saveNewImage(e.target.result)
          } else {
            self.saveNewImage(resizeImage(image))
          }
        }
      }
      reader.onerror = function () {}

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }
    },
    saveNewImage(image) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === 'image') {
          this.data[i].value = image
          this.$set(this.data, i, this.data[i])
          break
        }
      }
      document.getElementById('contents-input-image').value = ''
    },
    saveNewUrl() {
      if (this.newUrl) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id === 'url') {
            if (!this.data[i].value) {
              this.data[i].value = []
            }
            this.data[i].value.push(this.newUrl)
            this.$set(this.data, i, this.data[i])
            break
          }
        }
        this.newUrl = ''
      }
    },
    deleteUrl(index) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === 'url') {
          this.data[i].value.splice(index, 1)
          break
        }
      }
    },
    changeSelect(item) {
      if (this.useSubTypes && this.contentType && (item.id === 'type' || item.id === 'sub_type')) {
        const type = this.data.find(function (dt) {
          return dt.id === 'type'
        })
        this.refreshFields({
          type: type ? type.value : null,
        })
      }
    },
    refreshFields(args) {
      const type = args && args.type ? args.type : null
      let description = args && args.description ? args.description : null
      if (this.useSubTypes && this.contentType) {
        let subTypeIndex = 0
        let replaceDescription = false
        for (let i = this.data.length - 1; i > -1; i--) {
          if (this.data[i].id === 'sub_type') {
            subTypeIndex = i
            if (type && this.contentType[type] && this.contentType[type].sub_types) {
              this.data[i].visible = true
              this.data[i].items = this.contentType[type].sub_types
              if (
                !this.contentType[type].exclude_description_sub_types ||
                (this.contentType[type].exclude_description_sub_types &&
                  this.contentType[type].exclude_description_sub_types.indexOf(this.data[i].value) === -1)
              ) {
                replaceDescription = this.contentType[type].description
              }
            } else {
              this.data[i].visible = false
              this.data[i].items = []
            }
          }
          if (this.data[i].isDescription) {
            this.data.splice(i, 1)
          }
        }
        const newFields = []
        if (replaceDescription) {
          description = description && typeof description === 'string' ? JSON.parse(description) : description
          replaceDescription.forEach(function (it) {
            newFields.push({
              type: 'textarea',
              id: it.value,
              title: window.strings[it.label] ? window.strings[it.label] : it.label,
              value: description && description[it.value] ? description[it.value] : '',
              visible: true,
              isDescription: true,
            })
          })
        } else {
          newFields.push({
            type: 'textarea',
            id: 'description',
            title: window.strings['description'],
            value: description ? description : '',
            visible: true,
            isDescription: true,
          })
          if (this.hasTranslations) {
            this.hasTranslations.forEach(function (tr) {
              newFields.push({
                type: 'textarea',
                id: 'description_' + tr,
                title: window.strings['description'] + ' (' + tr.toUpperCase() + ')',
                value: '',
                visible: true,
                isDescription: true,
              })
            })
          }
        }
        this.data.splice(subTypeIndex + 1, 0, ...newFields)
      }
    },
    loadPdf(e) {
      const self = this
      if (!e.target.files.length) {
        return false
      }
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        for (let i = 0; i < self.data.length; i++) {
          if (self.data[i].id === 'pdf') {
            self.data[i].value = e.target.result
            break
          }
        }
        if (document.getElementById('input-pdf-value')) {
          document.getElementById('input-pdf-value').value = ''
        }
      }
    },
  },
}
</script>
